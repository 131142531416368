.cls-indicator {
  position: fixed;
  z-index: 9000;
  height: 100%;
  width: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

.cls-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*.cls-indicator .cls-inner-indicator-cube-grid div {
  width: 30px;
  height: 30px;
}*/



.cls-indicator .spinner {
  width: 100px;
  position: fixed;
  top: 50%;
  left: 47%;
}

.spinner > div {
  width: 30px;
  height: 30px;
  background-color: #685a7f;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
      -webkit-transform: scale(0)
  }

  40% {
      -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }

  40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
  }
}