.table{
   
   .radio,
   .checkbox{
        position: relative;
        height: 20px;
        display: block;
        width: 20px;
        padding: 0px 0px;
        margin: 0px 5px;
        text-align: center;
        
        .icons{
            left: 5px;
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 8px;
       vertical-align: middle;
       border-color: $light-gray;
   }
   
    > thead > tr > th{
        border-bottom-width: 1px;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $dark-gray;
        font-weight: $font-weight-normal;
        padding-bottom: 5px;
    }

   .td-actions .btn{
        @include opacity(0.36);
    
        &.btn-xs{
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    .td-actions{
        min-width: 90px;
    }
    
    > tbody > tr{
        position: relative;
        
        &:hover{
            .td-actions .btn{
                @include opacity(1);
            }
        }
    }
} 

.table-bigboy{
    > thead > tr > th{
        font-size: $font-size-h6;
        text-transform: uppercase;
        color: $dark-gray;
        font-weight: $font-weight-normal;
    }
    > tbody > tr > td{
        font-size: $font-size-base;
        
        b{
            display: block;
            margin-bottom: 5px;
        }
    } 
    .td-name,
    {
       font-weight: $font-weight-light;
       font-size: $font-size-h5;
   }
    .td-name{
        min-width: 240px;
    }
    .td-number{
        text-align: right;
        min-width: 110px;
        
        small{
            margin-right: 3px;
        }
    }
    
    .td-actions{
        width: 60px;
        min-width: auto;
        
        .btn{
            float: right;
        }
    }
    .img-container{
        width: 180px;
        height: 120px;
        overflow: hidden;
        display: block;
        
        border-radius: $border-radius-large;
        
        img{
            width: 100%;
        }    
    }
}

@include table-row-variant('success', $light-green);
@include table-row-variant('info', $light-azure);
@include table-row-variant('warning', $light-orange);
@include table-row-variant('danger', $light-red);



