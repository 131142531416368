@import "src/assets/styles/variables";
.notify-icon {
  position: absolute;
  top: 30%;
  font-size: 35px;
}

.notify-description {
  position: absolute;
  left: 60px;
  margin-top: 10px;
}

.notification-dismiss{
  &:hover {
    color: $gray !important;
  }
}