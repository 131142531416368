@import "src/assets/styles/variables";
.wrapper-input-edit-able {
    position: relative;
    .edit-button {
        cursor: pointer;
    }
    .input-edit-able {
        margin-top: 1px;
        font-size: 13px;
        padding: 2px 0;
        color: $deep-purple;
        border: none;
        outline: 0;
        border-radius: 4px;
        background:  #f4f5f7;
        position: absolute;
        box-shadow: 0 3px 6px rgba(111,111,111,0.2);
        z-index: 10;
        .fa {
            width: 25px;
        }
    }
    .input-edit-able:hover {
        color: $deep-purple;
        background: #ebecf0;
    }
    
    .bottom {
        bottom: -25px;
    }
    .top {
        top: -25px;
    }
    .input-edit-able-save {
        right: 1px;
    }
    
    .input-edit-able-cancel {
        right: 34px;
    }
      
}