@import "src/assets/styles/variables";
@import "src/assets/sass/lbd/_variables";

*,
html,
body {
  font-family: "Source Sans Pro", sans-serif;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

label {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $default-color;
  margin-bottom: 8px;
}

.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  // background-image: url(assets/img/full-screen-image-3.jpg) 0 0 no-repeat;
}

.sidebar .nav p{
  font-size: 14px;
}

.main-panel .main-content {
  padding: 0;
  
  .container-fluid {
    padding: 0 20px;

    .card {
      border-radius: none;
      box-shadow: none;
      background-color: transparent;
      .breadcrumb-wrapper {
        display: flex;
        align-items: baseline;
    
        .breadcrumb-title {
            font-size: 28px;
            color: $default-color;
            margin-right: 50px;
            text-transform: capitalize;
        }

        .breadcrumb {
          margin-bottom: 10px;
          padding-left: 0;
        }
      }
      
      .page-content {
        min-height: calc(100vh - 150px);
        padding: 20px;
        background-color: #ffffff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 2px;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .navbar-nav {
    margin: 1px 0;
    .dropdown-with-icons {
      // width: 174px;
    }
    .open {
      .dropdown-menu {
        // &:after {
        //   border-bottom: 15px solid #ffffff;
        //   border-left: 15px solid rgba(0, 0, 0, 0);
        //   border-right: 15px solid rgba(0, 0, 0, 0);
        //   left: 8px;
        // }
        // &:before {
        //   border-bottom: 15px solid rgba(0, 0, 0, 0.2);;
        //   border-left: 15px solid rgba(0, 0, 0, 0);
        //   border-right: 15px solid rgba(0, 0, 0, 0);
        //   left: 8px;
        // }
      }
    }
    .open .dropdown-menu > li {
      & > a {
        padding: 0;
      }

      &:first-child > a {
        padding: 0;
        color: black;
      }

      &:first-child > a:hover {
        padding: 0;
        color: black;
      }

      &:last-child > a {
        padding: 0;
      }
    }
  }
}

/* Margin */
.tb-mt-10 {
  margin-top: 10px;
}

.tb-mt-20 {
  margin-top: 20px;
}

.tb-mt-30 {
  margin-top: 30px;
}

.tb-mt-50 {
  margin-top: 50px;
}

.tb-margin-20 {
  margin: 20px;
}

.tb-margin-bottom-10 {
  margin-bottom: 10px
}
.tb-margin-bottom-50 {
  margin-bottom: 50px
}

.tb-margin-left-20 {
  margin-left: 20px;
}

.tb-margin-left-10 {
  margin-left: 10px;
}

.row-no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Justify-content */
.justify-content-center {
  display: flex;
  justify-content: center;
}

.table-actions {
  cursor: pointer;
  padding: 0 5px;
  font-size: 20px;
  color: $deep-purple;
}

a.disabled {
  color: #cacaca !important;
}

.card .form-group > label {
  text-transform: none;
}

/* Modal */

.modal-body {
  padding: 30px;

  .title {
    color: $deep-purple;
    font-size: $font-size-large;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
    font-size: $font-size-medium;
  }

  .button-row {
    margin: 0;
    text-align: right;
    button + button {
      margin-left: 10px;
    }
    .tabula-button-wrapper {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .css-1pcexqc-container {
    margin-top: 5px;
  }
}
