// ===FONT SIZE===
$font-size-x-small: 11px;
$font-size-small: 13px;
$font-size-normal: 15px;
$font-size-medium: 18px;
$font-size-large: 25px;
$font-size-x-large: 28px;
$font-size-xx-large: 35px;
// ===COLOR===
$white: rgb(255, 255, 255);
$light-gray: rgb(242, 242, 242);
$explosive: rgb(195, 195, 195);
$gray: rgb(124, 124, 124);
$blackish: rgb(47, 47, 47);
$deep-purple: rgb(67, 66, 93);
$stuck-red: rgb(207, 70, 54);
$riverside: rgb(132, 198, 232);
$tahoe-blue: rgb(37, 143, 214);
$grass-green: rgb(16, 122, 74);
$yellow: rgb(254, 202, 46);
$faded-green: rgb(150, 186, 167);
$sea-foam: rgb(129, 210, 219);
$citron: rgb(216, 194, 85);
$carrot: rgb(227, 146, 79);
$tang: rgb(253, 166, 67);
$eggplant: rgb(104, 90, 127);
$lavender: rgb(233, 233, 240);