/*! nouislider - 11.0.3 - 2018-01-21 14:04:07 */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
/*
This is the official noUiSlider styles, with minor changes by Creative Tim
*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Offset direction
*/
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 4px;
}
.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  left: -7.5px;
  top: -6px;
}
.noUi-vertical {
  width: 4px;
}
.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  left: -6px;
  top: -7.5px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -7.5px;
  left: auto;
}
/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
  background: #e5e5e5;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border-radius: 50%;
  cursor: pointer;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: 0 1px 1px #fff inset, 0 1px 2px rgba(0,0,0,.4);
  box-shadow: inset 0 1px 1px #fff, 0 1px 2px rgba(0,0,0,.4);
  background: #fff;
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#fff),color-stop(100%,#f1f1f2));
  background: -webkit-linear-gradient(top,#fff,#f1f1f2);
  background: -o-linear-gradient(top,#fff 0,#f1f1f2 100%);
  background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#f1f1f2));
  background: linear-gradient(180deg,#fff 0,#f1f1f2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f1f1f2",GradientType=0);
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 15px;
  height: 1px;
  left: 6px;
  top: 15px;
}
/* Disabled state;
*/
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
*
*/
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
*
*/
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
*
*/
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  margin-bottom: 9px;
  width: 40px;
}
.noUi-tooltip:after, .noUi-tooltip:before {
  content: "";
  display: inline-block;
  position: absolute;
}
.noUi-tooltip:before {
  border-top: 12px solid #dcd9d1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  right: 12px;
  bottom: -12px;
}
.noUi-tooltip:after {
  border-top: 21px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  right: 8px;
  bottom: -11px;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-handle:focus{
  outline: none;
}

.slider-primary{
  & .noUi-connect,
  &.noUi-connect{
    background: $color-azure;
  }

  & .noUi-handle{
    border-color: $color-azure;
  }
}
.slider-info{
  & .noUi-connect,
  &.noUi-connect{
    background: $color-blue;
  }

  & .noUi-handle{
    border-color: $color-blue;
  }
}
.slider-success{
  & .noUi-connect,
  &.noUi-connect{
    background: $color-green;
  }

  & .noUi-handle{
    border-color: $color-green;
  }
}
.slider-warning{
  & .noUi-connect,
  &.noUi-connect{
    background: $color-orange;
  }

  & .noUi-handle{
    border-color: $color-orange;
  }
}
.slider-danger{
  & .noUi-connect,
  &.noUi-connect{
    background: $color-red;
  }

  & .noUi-handle{
    border-color: $color-red;
  }
}
