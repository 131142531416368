@import "src/assets/styles/variables";

@mixin status($color) {
  border: 1px solid $color;
  background: $color;
  width: 80px;
  height: 25px;
  color: $white;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-transform: capitalize;
}

.table-list-wrapper {
  // min-width: 700px;
  .pending {
    @include status($grass-green);
  }
  .active {
    @include status($tahoe-blue);
  }
  .suspended {
    @include status(#a8a8a8);
  }
  .expired {
    @include status($stuck-red);
  }
  .locked {
    @include status($carrot);
  }
}
.ReactTable.-striped .rt-tr.-odd {
  background: #fafafa;
}
.ReactTable {
  display: block;
  // float: left;
  // width: 100%;
  .rt-tbody {
    overflow: hidden;
  }
  .rt-tr {
    height: 50px;
  }
  .rt-td[data-content="td-empty"]::before {
    content: "-";
  }
  .rt-td {
    font-size: $font-size-small !important;
    color: #000000;
  }
  .rt-thead .rt-th {
    font-weight: 600;
    color: #7c7c7c;
  }
}
