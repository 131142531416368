@import "src/assets/styles/variables";
.confirm-modal {
    .modal-header {
        .close {
            font-size: 25px;
        }
    }
    .confirm-title {
        font-size: $font-size-medium;
        word-break: break-all;
        padding-right: 25px;
    }
    .modal-body .content {
        font-size: $font-size-normal;
        word-break: break-all;
    }
}