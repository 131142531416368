@import "src/assets/styles/variables";
// .tabula-button {
//   @include button-reset;
//   font-size: $font-size-medium;
// }

@mixin button($background, $color, $border, $radius, $width, $height, $focus-bg-color, $focus-color, $font-weight) {
  background: $background;
  color: $color;
  border: $border solid $color;
  border-radius: $radius;
  width: $width;
  height: $height;
  font-weight: $font-weight;
  &:focus,
  &:active,
  &:hover {
    background: $focus-bg-color;
    color: $focus-color;
    border: 1px solid $color;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.6;
    background: $background;
    color: $color;
  }
}

.rectangle-purple-white {
  @include button($deep-purple, white, 1px, 4px, 185px, 50px, #37364d, white, 400);
  border: none;
  &:focus,
  &:active,
  &:hover {
    border: none;
  }
}

.rectangle-white-purple {
  @include button($white, $deep-purple, 1px, 4px, 185px, 50px, $white, $deep-purple, 400);
}

.rectangle-blue-white {
  @include button($tahoe-blue, white, 1px, 4px, 185px, 50px, #2184c6, white, 400);
  border: none;
  &:focus,
  &:active,
  &:hover {
    border: none;
  }
}

.rectangle-white-blue {
  @include button($white, $tahoe-blue, 1px, 4px, 185px, 50px, $white, $tahoe-blue, 400);
}

.circle-white-purple {
  @include button($white, $deep-purple, 2px, 23px, 135px, 45px, #37364d, $white, 600);
  text-transform: uppercase;
}

.circle-white-blue {
  @include button($white, $tahoe-blue, 2px, 23px, 135px, 45px, #2184c6, $white, 600);
  text-transform: uppercase;
}