@import "src/assets/styles/variables";

.form-select {
  .css-bg1rzq-control {
    border-color: #e9e9f0;
    &:hover {
      border-color: $deep-purple;
    }
  }
  .css-1szy77t-control {
    border: 1px solid $tahoe-blue;
    box-shadow: none;
    &:hover {
      border: 1px solid $tahoe-blue;
    }
  }
  .css-xo7z33-option {
    color: $deep-purple;
    background: rgba(132, 198, 232, 0.2);
  }
}
.form-select.error > div {
  border-color: $stuck-red;
  &:hover,
  &:active {
    border-color: $stuck-red;
  }
}
