@import "src/assets/sass/lbd/_variables";
@import "src/assets/styles/variables";

.error-page {
  text-align: center;
  transform: translateY(150%);
  .number {
    color: $carrot;
    font-size: 5vw;
    font-weight: $font-weight-bold;
  }
  .short-description {
    font-size: 1.5vw;
    font-weight: $font-weight-bold;
    margin-top: -1.5vw;
  }
  .detail-description {
    margin-top: 1vh;
    font-size: 1vw;
  }
  .go-back {
    font-size: 1vw;
    margin-top: 2vw;
  }
}

.invalid-link-page {
  padding-top: 100px;
  margin: auto;
  width: 550px;
  text-align: center;
  .card {
    padding: 20px;
    .content {
      padding-top: 30px;
    }
  }
  .title {
    font-size: 25px;
    .tabula-learning {
      color: $deep-purple;
      font-weight: 600;
    }
    .admin-site {
      margin-left: 5px;
      color: $gray;
      font-weight: 400;
    }
  }

  .invalid-link {
    font-size: $font-size-normal;
  }
}

