@import "src/assets/styles/variables";

.contextMenu-hidden {
  display: none;
}

.contextMenu {
  position: absolute;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  // padding: 10px 0;
  z-index: 50;
  .cls-contextMenu-icon {
    top: 2px;
    margin-right: 5px;
  }
}

.contextMenu--option {
  padding: 10px 15px;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  color: #2b2b2a;
  position: relative;

  .contextMenu-note {
    color: $gray;
    cursor: default;
    padding-bottom: 10px;
    word-break: break-word;
  }
  .contextMenu-title {
    font-size: $font-size-normal;
  }
  &.info {
    background-color: #2e4057;
    color: #fff;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  &:not(.info) {
    .glyphicon {
      display: none;
    }
    &:hover {
      background-color: rgba(158, 158, 158, 0.15);
      cursor: pointer;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  &:not(.info):active {
    background-color: #e6e6e6;
  }
}

.contextMenu--option.disabled {
  color: #999999;
  pointer-events: none;
}

.contextMenu--option.separator {
  margin-bottom: 11px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -6px;
    right: 0;
    width: 100%;
    border-bottom: 1px solid #ececec;
  }
}
