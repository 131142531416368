.menu-btn {
  color: white;
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
}
.navbar-wrapper {
  display: inline-flex;
  justify-content: flex-end;
}
