.btn{
    border-width: $border-thin;
    background-color: $transparent-bg;
    font-weight: $font-weight-normal;
    font-size: 18px;

    @include opacity(1);
    padding: $padding-base-vertical $padding-base-horizontal;

    @include btn-styles($default-color, $default-states-color, $transparent-bg);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &.btn-icon{
        padding: $padding-base-vertical;
    }

    .btn-label{
        .fa{
            width: 13px;
        }
    }

    .fa{
        width: 20px;
    }
}

// Apply the mixin to the buttons
.btn-default { @include btn-styles($color-purple, $default-states-color, #37364d); }
.btn-primary { @include btn-styles($primary-color, $primary-states-color, $hover-bg-blue); }
.btn-success { @include btn-styles($success-color, $success-states-color, $transparent-bg); }
.btn-info    { @include btn-styles($info-color, $info-states-color, $transparent-bg); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color, $transparent-bg); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color, $transparent-bg); }

.btn-neutral {
    @include btn-styles($white-color, $white-color, $transparent-bg);

    &:active,
    &.active,
    .open > &.dropdown-toggle{
         background-color: $white-color;
         color: $default-color;
    }

    &.btn-fill,
    &.btn-fill:hover,
    &.btn-fill:focus{
        color: $default-color;
    }

    &.btn-fill:hover,
    &.btn-fill:focus{
        @include opacity(.86);
    }

    &.btn-simple:active,
    &.btn-simple.active{
        background-color: transparent;
    }
}

.btn{
     &:disabled,
     &[disabled],
     &.disabled{
        @include opacity(.5);
    }
}
.btn-round{
    border-width: $border-thin;
    border-radius: $btn-round-radius !important;
    padding: $padding-round-vertical $padding-round-horizontal;
    min-width: 0;

    &.btn-icon{
        padding: $padding-round-vertical;
    }
}
.btn-simple{
    border: $none;
    font-size: $font-size-medium;
    padding: $padding-base-vertical $padding-base-horizontal;

    &.btn-icon{
        padding: $padding-base-vertical;
    }
}
.btn-lg{
   @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
   font-weight: $font-weight-normal;
}
.btn-sm{
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
.btn-xs {
    @include btn-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $border-radius-small);
}
.btn-wd {
    min-width: 140px;
}

.btn-group.select{
    width: 100%;
}
.btn-group.select .btn{
    text-align: left;
}
.btn-group.select .caret{
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}
.btn-group>.btn{
  opacity: 1;
  &:not(:first-child):not(.dropdown-toggle){
    border-left: none;
  }
}
